import React, { useRef } from "react";
import { Container, useMediaQuery } from "@mui/material";
import CampaignNavigation from "./CampaignNavigation";
import HeroSection from "./HeroSection";
import LogoCarousel from "./LogoCarousel";
import AuditToolProcess from "./AuditToolProcess";
import FeaturesSection from "./FeaturesSection";
import MetricsSection from "./MetricsSection";
import PlatformCompatibility from "./PlatformCompatibility";
import FAQSection from "./FaqSection";
import ContactForm from "./ContactForm";
import theme from "../../theme";

const SpotrueHomePage = () => {
  const contactFormRef = useRef<HTMLDivElement | null>(null);
  // Create a reference to the ContactForm component
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

 const scrollToContactForm = () => {
  if (contactFormRef.current) {
    const headerOffset = isMobile?55:75; // Adjust based on your header height
    const elementPosition = contactFormRef.current.offsetTop;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }
};

  return (
    <Container
      maxWidth="lg"
      sx={{
        bgcolor: "background.default",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeroSection onRequestDemo={scrollToContactForm} />
      <CampaignNavigation />
      <AuditToolProcess />
      <LogoCarousel />
      <FeaturesSection />
      <MetricsSection />
      <PlatformCompatibility />
      <FAQSection />
      <ContactForm ref={contactFormRef} />
    </Container>
  );
};

export default SpotrueHomePage;
