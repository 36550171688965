import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Step,
  StepLabel,
  Stepper,
  IconButton,
  StepConnector,
  styled,
  Popover,
  useTheme,
  useMediaQuery,
  StepContent,
  Button,
} from "@mui/material";
import { Info, InfoOutlined } from "@mui/icons-material";
import { RadioButtonUnchecked, RadioButtonChecked } from "@mui/icons-material";
import { ProcessTimestamps } from "./AuditRequestPage";
import { useAuthContext } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";

interface RequestStatusProgressProps {
  requestStatus: string;
  dueDate?: string; // Optional due date for processing completion
  allowRequestEdit?: boolean; // Optional flag for rejected requests
  comments?: string; // Optional comments for rejected requests
  clientUrl?: string;
  timeStamps: ProcessTimestamps;
  gogigRequestId: string;
  campaignType?: string;
}

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    height: "20px",
    borderWidth: 1, // Increase line thickness
    borderColor: theme.palette.grey[400], // Set color for the line
  },
}));

const RequestStatusProgress: React.FC<RequestStatusProgressProps> = ({
  requestStatus,
  dueDate,
  allowRequestEdit,
  clientUrl,
  timeStamps,
  gogigRequestId,
  campaignType,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStep, setSelectedStep] = useState<number | null>(null);
  const [showDescription, setShowDescription] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { authHeader } = useAuthContext();
  const [activeStep, setActiveStep] = useState<number | -1>(-1);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const colors = {
    completed: "#4CAF50",
    inProgress: "#FF9800",
    notStarted: "#9E9E9E",
    rejected: "#D32F2F",
    default: "#B0BEC5",
  };
  const formatDueDate = (dueDate:string|undefined) => {
    // Convert the string to a Date object
    if(!dueDate) return;
    const dateObj = new Date(dueDate);
  
    // Extract year, month, and day using UTC methods
    const formattedDate = dateObj.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long', 
      day: 'numeric',
      year: 'numeric',
    });
  
    return formattedDate;
  };
  useEffect(() => {
    const stepIndex = statusSteps.findIndex(
      (step) => step.status === requestStatus
    );
    setActiveStep(stepIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader]);

  const statusSteps = [
    {
      label:
        clientUrl === null || requestStatus === "requestCreationPending"
          ? "Request Creation Incomplete"
          : "Request Created",
      status:
        requestStatus === "requestCreationPending"
          ? "requestCreationPending"
          : "requestCreated",
      start:
        clientUrl === null || requestStatus === "requestCreationPending"
          ? null
          : timeStamps.requestCreatedAt,
      end: null,
      nextStep:
        clientUrl === null
          ? "Click the 'COMPLETE YOUR REQUEST' button below to upload your files and complete your audit request creation."
          : requestStatus === "requestCreationPending"
          ? "Your files are uploaded but not submitted. Please click 'COMPLETE YOUR REQUEST' to proceed."
          : "The review process for your audit request will begin shortly. You will receive updates via email or can check this page for more information.",
    },
    {
      label: "Request Under Review",
      status: "requestUnderReview",
      start: timeStamps.reviewStartedAt,
      end: timeStamps.reviewCompletedAt,
      nextStep: allowRequestEdit
        ? "Please review the comments, make the necessary corrections, and re-upload the files. You can view the comments on this page and make updates using the 'Edit Your Campaign' button. Once updated, your request will go under review again."
        : "Once the review is complete, you will receive an update. If everything is approved, we will move forward with the auditing process.",
    },
    {
      label:
        requestStatus === "rejected" ? "Request Rejected" : "Request Approved",
      status: requestStatus === "rejected" ? "rejected" : "requestApproved",
      start: timeStamps.reviewCompletedAt,
      end: timeStamps.reviewCompletedAt,
      nextStep:
        requestStatus === "rejected"
          ? "We regret to inform you that your request has been rejected. Please create a new campaign request and upload the correct files to proceed. We apologize for any inconvenience caused."
          : `The auditing process will begin shortly and is expected to be completed by ${formatDueDate(
              dueDate
            )}. Once the results are reviewed, your report will be available for download.`,
    },
    {
      label: "Processing",
      status: "processing",
      start: timeStamps.processingStartedAt,
      end: timeStamps.processingCompletedAt,
      nextStep: `Your report is being processed and is expected to be ready by ${formatDueDate(
        dueDate
      )}. Updates will be shared via email or available on this page.`,
    },
    {
      label: "Results Under Review",
      status: "resultsUnderReview",
      start: timeStamps.resultReviewStartedAt,
      end: timeStamps.resultReviewCompletedAt,
      nextStep: `Your audit results are under review and the report is expected by ${formatDueDate(
        dueDate
      )}. Any changes will be shared via email or updated on this page.`,
    },
    {
      label: "Completed",
      status: "completed",
      start: timeStamps.resultReviewCompletedAt,
      end: timeStamps.resultReviewCompletedAt,
      nextStep:
        "Your audit request is successfully completed! Click the 'VIEW REPORT' button above or navigate to the dashboard to access your audit report.",
    },
  ].slice(0, requestStatus === "rejected" ? 3 : undefined);

  const statusStepDetails = [
    {
      label:
        clientUrl === null ? "Request Creation Incomplete" : "Request Created",
      descriptions: [
        {
          status: [
            "requestCreationPending",
            "requestCreated",
            "processing",
            "resultsUnderReview",
            "requestUnderReview",
            "completed",
            "rejected",
            "requestApproved",
          ],
          color:
            clientUrl === null || requestStatus === "requestCreationPending"
              ? colors.rejected
              : colors.completed,
          description:
            clientUrl === null || requestStatus === "requestCreationPending"
              ? "Your request creation is incomplete"
              : "Your request has been successfully created",
          logo: <RadioButtonChecked />,
        },
      ],
    },
    {
      label: "Request Under Review",
      descriptions: [
        {
          status: ["requestCreated", "requestCreationPending"],
          color: colors.notStarted,
          description: "The review process has not started yet.",
          logo: <RadioButtonUnchecked />,
        },
        {
          status: "requestUnderReview",
          color: colors.inProgress,
          description:
            "The review process for your audit request has now begun!",
          logo: <RadioButtonChecked />,
        },
        {
          status: [
            "processing",
            "resultsUnderReview",
            "completed",
            "rejected",
            "requestApproved",
          ],
          color: colors.completed,
          description:
            "The review of your audit request has been successfully completed!",
          logo: <RadioButtonChecked />,
        },
      ],
    },
    {
      label:
        requestStatus === "rejected" ? "Request Rejected" : "Request Approved",
      descriptions: [
        {
          status: ["requestCreated", "requestCreationPending"],
          color: colors.notStarted,
          description: "The review process has not started yet.",
          logo: <RadioButtonUnchecked />,
        },
        {
          status: "requestUnderReview",
          color: colors.notStarted,
          description:
            "The review process for your audit request has now begun!.",
          logo: <RadioButtonUnchecked />,
        },
        {
          status: "requestApproved",
          color: colors.completed,
          description: "Your request has been approved! .",
          logo: <RadioButtonChecked />,
        },
        {
          status: "rejected",
          color: allowRequestEdit ? colors.inProgress : colors.rejected,
          description: allowRequestEdit
            ? "Your request has been commented on."
            : "Your request has been rejected due to incorrect files.",
          logo: <RadioButtonChecked />,
        },
        {
          status: ["processing", "resultsUnderReview", "completed"],
          color: colors.completed,
          description: "your request for auditing has been approved",
          logo: <RadioButtonChecked />,
        },
      ],
    },
    {
      label: "Processing",
      descriptions: [
        {
          status: [
            "requestCreated",
            "requestCreationPending",
            "requestUnderReview",
            "rejected",
            "requestApproved",
          ],
          color: colors.notStarted,
          description:
            "Once the above steps are completed, your request auditing process will begin.",
          logo: <RadioButtonUnchecked />,
        },
        {
          status: "processing",
          color: colors.inProgress,
          description: "The auditing process for your request has now begun.",
          logo: <RadioButtonChecked />,
        },
        {
          status: ["resultsUnderReview", "completed"],
          color: colors.completed,
          description:
            "The auditing process for your request has been successfully completed.",
          logo: <RadioButtonChecked />,
        },
      ],
    },
    {
      label: "Results Under Review",
      descriptions: [
        {
          status: [
            "requestCreated",
            "requestCreationPending",
            "requestUnderReview",
            "rejected",
            "requestApproved",
            "processing",
          ],
          color: colors.notStarted,
          description:
            "Once the above steps are completed, the review of your request auditing results will begin.",
          logo: <RadioButtonUnchecked />,
        },
        {
          status: "resultsUnderReview",
          color: colors.inProgress,
          description:
            "The review of your audit request results has officially begun! ",
          logo: <RadioButtonChecked />,
        },
        {
          status: "completed",
          color: colors.completed,
          description: "You can now download your report. Thank you!",
          logo: <RadioButtonChecked />,
        },
      ],
    },
    {
      label: "Completed",
      descriptions: [
        {
          status: [
            "requestCreated",
            "requestCreationPending",
            "requestUnderReview",
            "rejected",
            "requestApproved",
            "processing",
            "resultsUnderReview",
          ],
          color: colors.notStarted,
          description:
            "Once the above steps are completed, you will be able to download the report.",
          logo: <RadioButtonUnchecked />,
        },
        {
          status: "completed",
          color: colors.completed,
          description: "You can now download your report. Thank you!",
          logo: <RadioButtonChecked />,
        },
      ],
    },
  ].slice(0, requestStatus === "rejected" ? 3 : undefined);
  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedStep(index);
    setAnchorEl(event.currentTarget);
    setShowDescription((prev) => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedStep(null);
    setShowDescription(false);
  };
  const formatDate = (date?: Date): string =>
    date ? new Date(date).toLocaleString() : "N/A";

  const getStatusStepInfo = (stepIndex: number, status: string) => {
    const step = statusStepDetails[stepIndex];
    const descriptionObj =
      step.descriptions.find((d) =>
        Array.isArray(d.status)
          ? d.status.includes(status)
          : d.status === status
      ) || step.descriptions.find((d) => d.status === "default");

    return {
      description: descriptionObj?.description,
      color: descriptionObj?.color,
      logo: descriptionObj?.logo,
    };
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row", // Stack components on smaller screens
        padding: theme.spacing(2),
        gap: theme.spacing(2),
        overflowX: "hidden",
      }}
    >
      <Stepper
        orientation={"vertical"}
        connector={<CustomConnector />}
        activeStep={activeStep}
        sx={{
          width: "100%",
          overflowX: isMobile ? "auto" : "hidden",
          mt: statusSteps.length === 3 ? 0 : 0,
          height: statusSteps.length === 3 ? "100%" : "100%",
        }}
      >
        {statusSteps.map((step, index) => {
          const { color, logo } = getStatusStepInfo(index, requestStatus);
          return (
            <Step key={index} sx={{ maxWidth: "500px" }}>
              <StepLabel
                StepIconProps={{
                  icon: logo
                    ? React.cloneElement(logo, { sx: { color } })
                    : null,
                }}
                sx={{
                  alignItems: "flex-start", // Aligns the icon with the label's start
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {/* Main Label */}
                  <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "black",

                        borderRadius: "8px",
                      }}
                    >
                      {step.label}
                    </Typography>
                    <IconButton
                      onClick={(event) => handleClick(event, index)}
                      size="small"
                      sx={{ padding: 0 }} // Removes extra space around the button
                    >
                      {showDescription && selectedStep === index ? (
                        <Info sx={{ color }} />
                      ) : (
                        <InfoOutlined sx={{ color }} />
                      )}
                    </IconButton>
                  </Box>
                  {/* Start and End Timestamps */}

                  {step.start && (
                    <Typography variant="caption" color="textSecondary">
                      {formatDate(step.start)}
                    </Typography>
                  )}
                </Box>
              </StepLabel>
              <StepContent>
                {/* Next Step */}
                {requestStatus === step.status && (
                  <Box sx={{ marginTop: "8px" }}>
                    <Typography variant="caption" color="textSecondary">
                      <strong>Next Step:</strong> {step.nextStep}
                    </Typography>
                    {(clientUrl === null ||
                      requestStatus === "requestCreationPending") && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() =>
                          navigate(
                            `/upload-createRequest/2/${campaignType}/${gogigRequestId}`
                          )
                        } // Replace with the correct route
                        sx={{
                          marginTop: "8px", // Adds spacing between the text and the button
                          textTransform: "none",
                          display: "block", // Ensures the button takes full width in small screen
                          width: { xs: "100%", sm: "auto" }, // Button takes full width on mobile, auto on larger screens
                        }}
                      >
                        Complete the Request
                      </Button>
                    )}
                  </Box>
                )}
              </StepContent>
            </Step>
          );
        })}
      </Stepper>

      <Popover
        open={Boolean(anchorEl) && showDescription}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          maxWidth: "90vw",
          p: 2,
        }}
      >
        {selectedStep !== null && (
          <Box sx={{ p: 2 }}>
            <Typography variant="body2">
              {getStatusStepInfo(selectedStep, requestStatus).description}
            </Typography>
          </Box>
        )}
      </Popover>
    </Box>
  );
};

export default RequestStatusProgress;
