import React, { forwardRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Container,
  alpha,
  CircularProgress,
  Grid,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import theme from "../../theme";
import saveClientFormData from "../../clients/saveClientFormData";
import { useSnackbar } from "../../components/snackbarComponent/useSnackbar";
import GlobalSnackbar from "../../components/snackbarComponent/Snackbar";

const ContactForm = forwardRef<HTMLDivElement, {}>((props, ref) => {
  const { snackbar, openSnackbar, closeSnackbar } = useSnackbar();
  const [buttonLabel, setButtonLabel] = useState("Submit");
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    quantity: false,
    transports: false,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let formErrors = { ...errors };
    let isValid = true;

    // Validate required fields
    if (!formData.name) {
      formErrors.name = true;
      isValid = false;
    }

    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = true;
      isValid = false;
    }

    // if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
    //   formErrors.phone = true;
    //   isValid = false;
    // }

    // Convert quantity to a number and check if it's valid
    // if (
    //   !formData.quantity ||
    //   !["0-100", "100-500", "500-1000", "1000-5000", "5000+"].includes(
    //     formData.quantity
    //   )
    // ) {
    //   formErrors.quantity = true;
    //   isValid = false;
    // }

    // if (formData.selectedTransports.length === 0) {
    //   formErrors.transports = true;
    //   isValid = false;
    // }

    setErrors(formErrors);
    if (!isValid) {
      openSnackbar("please fill all the details!", "error");
      return;
    }
    if (isValid) {
      setLoading(true);
      try {
        await saveClientFormData({
          fullName: formData.name,
          emailId: formData.email,
          companyName: formData.companyName,
          // phoneNumber: formData.phone,
          // transitMediaToVerify: formData.selectedTransports.join(","),
          // quantity: formData.quantity,
        });
        setFormSubmitted(true);
        openSnackbar("Form submitted successfully!", "success");
        setButtonLabel("Submitted Successfully");
      } catch (error) {
        console.error("Error saving form data:", error);

        openSnackbar("Failed to submit form. Please try again.", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleInputChange = (field: string, value: string) => {
    let updatedErrors = { ...errors };

    if (field === "name") {
      updatedErrors.name = value.trim() === "";
    } else if (field === "email") {
      updatedErrors.email = !/\S+@\S+\.\S+/.test(value);
    }
    // } else if (field === "phone") {
    //   updatedErrors.phone = !/^\d{10}$/.test(value);
    // } else if (field === "quantity") {
    //   const validRanges = [
    //     "0-100",
    //     "100-500",
    //     "500-1000",
    //     "1000-5000",
    //     "5000+",
    //   ];
    //   updatedErrors.quantity = !validRanges.includes(value);
    // }

    setErrors(updatedErrors);
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  // const handleTransportChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   label: string
  // ) => {
  //   const newTransports = event.target.checked
  //     ? [...formData.selectedTransports, label]
  //     : formData.selectedTransports.filter((item) => item !== label);

  //   setFormData({
  //     ...formData,
  //     selectedTransports: newTransports,
  //   });

  //   setErrors({
  //     ...errors,
  //     transports: newTransports.length === 0,
  //   });
  // };

  return (
    <Container ref={ref} maxWidth="lg" sx={{py:5}} >
      <GlobalSnackbar
        open={snackbar.open}
        message={snackbar.message}
        onClose={closeSnackbar}
        severity={snackbar.severity}
        vertical={"top"}
        horizontal={"center"}
      />
      {!formSubmitted ? (
        <Box
          sx={{
            
            bgcolor: theme.palette.background.default,
            px: { xs: 0, sm: 0 },
          }}
        >
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontWeight: 700,
              color: theme.palette.text.primary, // Primary text color
              fontSize: { xs: "2rem", md: "2.5rem" },
              lineHeight: 1.2,
              textAlign: "center",
              mb: 1,
            }}
          >
            Book a demo today!
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: theme.palette.text.secondary,
              mb: 1,
              maxWidth: "600px",
              lineHeight: 1.5,
              textAlign: "center", // Changed to always be centered
              margin: "0 auto", // Added to center the maxWidth container
            }}
          >
            Share your contact details, and our team will reach out to
            understand your requirements and schedule a personalized demo for
            you.
          </Typography>
          <Card
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              overflow: "hidden",
              bgcolor: theme.palette.background.default,

              gap: { xs: 2, md: 0 },
            }}
          >
            <CardContent
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Company Name"
                      margin="normal"
                      value={formData.companyName}
                      onChange={(e) =>
                        handleInputChange("companyName", e.target.value)
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          bgcolor: alpha(theme.palette.primary.main, 0.02),
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Name*"
                      margin="normal"
                      value={formData.name}
                      onChange={(e) =>
                        handleInputChange("name", e.target.value)
                      }
                      error={errors.name}
                      helperText={errors.name ? "Full name is required" : ""}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          bgcolor: alpha(theme.palette.primary.main, 0.02),
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email*"
                      type="email"
                      margin="normal"
                      value={formData.email}
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                      error={errors.email}
                      helperText={
                        errors.email ? "Please enter a valid email address" : ""
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          bgcolor: alpha(theme.palette.primary.main, 0.02),
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{
                    mt: 2,
                    py: 1.5,
                    borderRadius: 2,
                    textTransform: "none",
                    fontSize: "1.1rem",
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    buttonLabel
                  )}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Box>
      ) : (
        <Box
          sx={{
            mt: 1,
            p: 4,
            mb: 2,
            borderRadius: 2,
            bgcolor: alpha(theme.palette.success.main, 0.1),
            border: `1px solid ${theme.palette.success.main}`,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: theme.palette.success.main,
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CheckCircleIcon sx={{ mr: 1, fontSize: 30 }} />
            Thank you for submitting your details! Our team will get back to you
            shortly.
          </Typography>
        </Box>
      )}
    </Container>
  );
});

export default ContactForm;
