import React, { useState, useEffect } from "react";
import { Button, Box, Typography, CircularProgress, Alert } from "@mui/material";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SendIcon from "@mui/icons-material/Send"; // New import for the send button
import { GoogleMapsApiKey } from "../clients/baseUrls";

declare global {
  interface Window {
    google: any;
    initMap?: () => void;
  }
}

const LocationPage = () => {
  const [location, setLocation] = useState<{
    lat: number;
    lng: number;
    address: string;
    city: string;
    area: string;
    pincode: string;
    fullAddress: string;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [whatsapp,setWhatsapp]= useState(false);
  const [error, setError] = useState<string | null>(null);
  const [locationPermissionDenied, setLocationPermissionDenied] = useState(false);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [sending, setSending] = useState(false); // New state for API call

  useEffect(() => {
    const loadGoogleMaps = () => {
      if (window.google) {
        setGoogleMapsLoaded(true);
        return;
      }

      const existingScript = document.querySelector(
        `script[src^="https://maps.googleapis.com/maps/api/js"]`
      );
      if (existingScript) {
        existingScript.addEventListener('load', () => setGoogleMapsLoaded(true));
        return;
      }

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GoogleMapsApiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      
      script.onload = () => {
        if (window.google && window.google.maps) {
          setGoogleMapsLoaded(true);
        } else {
          setError("Google Maps API loaded but not properly initialized");
        }
      };
      
      script.onerror = () => {
        setError("Failed to load Google Maps API. Please check your internet connection.");
      };

      document.head.appendChild(script);
    };

    loadGoogleMaps();

    return () => {
      // Cleanup if needed
    };
  }, []);

  const handleRequestPermission = () => {
    if (!googleMapsLoaded) {
      setError("Google Maps is still loading. Please try again in a moment.");
      return;
    }

    setLoading(true);
    setError(null);
    setLocationPermissionDenied(false);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;

            const geocoder = new window.google.maps.Geocoder();
            const response = await geocoder.geocode({ location: { lat, lng } });

            if (response.results.length > 0) {
              const mostAccurateResult = response.results.find(
                (result: any) => result.geometry.location_type === "ROOFTOP"
              ) || response.results[0];

              const address = mostAccurateResult.formatted_address;
              let city = "";
              let area = "";
              let pincode = "";

              mostAccurateResult.address_components.forEach((component: any) => {
                if (component.types.includes("locality")) {
                  city = component.long_name;
                }
                if (component.types.includes("sublocality_level_1")) {
                  area = component.long_name;
                }
                if (component.types.includes("postal_code")) {
                  pincode = component.long_name;
                }
              });

              const fullAddress = `${address}`;

              setLocation({
                lat,
                lng,
                address,
                city,
                area,
                pincode,
                fullAddress
              });

            }
          } catch (error) {
            console.error("Geocoding Error:", error);
            setError("Failed to get address details. Please try again.");
          } finally {
            setLoading(false);
          }
        },
        (error) => {
          console.error("Geolocation Error:", error);
          setLoading(false);
          setLocationPermissionDenied(true);
          
          switch (error.code) {
            case 1:
              setError("Location permission denied. Please enable location services.");
              break;
            case 2:
              setError("Location unavailable. Check your connection.");
              break;
            case 3:
              setError("Location request timed out.");
              break;
            default:
              setError("Failed to get location.");
          }
        }
      );
    } else {
      setLoading(false);
      setError("Geolocation is not supported by this browser.");
    }
  };

  const handleWhatsAppRedirect = () => {
    window.open("https://wa.me/", "_blank");
  };

  // New function to handle sending location data
  const handleSendLocation = () => {
    if (!location) return;
    
    setSending(true);
    
    // For now, just console.log the coordinates
    console.log("Latitude:", location.lat);
    console.log("Longitude:", location.lng);
    console.log("Full Address:", location.fullAddress);
    setSending(false);
    setWhatsapp(true);
  };

  return (
    <Box sx={{ 
      p: 3, 
      maxWidth: 500, 
      margin: "0 auto",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      gap: 2
    }}>
      <Typography variant="h5" gutterBottom>
        Share Your Location
      </Typography>

      {location ? (
        <Box sx={{ textAlign: "left", mb: 2 }}>
          <Typography><strong>Full Address:</strong> {location.fullAddress}</Typography>
          <Typography><strong>Coordinates:</strong> {location.lat.toFixed(6)}, {location.lng.toFixed(6)}</Typography>
        </Box>
      ) : (
        <Typography>
          Click below to share your current location
        </Typography>
      )}

      <Button
        variant="contained"
        size="large"
        startIcon={loading ? <CircularProgress size={24} /> : <GpsFixedIcon />}
        onClick={handleRequestPermission}
        disabled={loading || (location !== null)}
        sx={{ mt: 2 }}
        fullWidth
      >
        {loading ? "Getting Location..." : "Use Current Location"}
      </Button>

      {location && (
        
        <>
        {/* New Send Location Button */}
        <Button
            variant="contained"
            size="large"
            startIcon={sending ? <CircularProgress size={24} /> : <SendIcon />}
            onClick={handleSendLocation}
            disabled={sending}
            sx={{ mt: 1 }}
            fullWidth
          >
            {sending ? "Sending..." : "Send Location"}
          </Button>
          <Button
            variant="contained"
            color="success"
            size="large"
            startIcon={<WhatsAppIcon />}
            onClick={handleWhatsAppRedirect}
            disabled={!whatsapp}
            sx={{
              backgroundColor: "#25D366",
              "&:hover": { backgroundColor: "#128C7E" },
            }}
            fullWidth
          >
            Continue to WhatsApp
          </Button>
        </>
        
      )}
      

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      {locationPermissionDenied && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          Please enable location permission from settings and try again!
        </Alert>
      )}
    </Box>
  );
};

export default LocationPage;