import {
  Box,
  Container,
} from "@mui/material";
import SignUpComponent from "../components/signUp/SignUpComponent";
import { useAuthContext } from "../providers/AuthProvider";
import LoggedInUserInfo from "./LoggedInUserInfo";

const SignUpPage = () => {
  const { isAuthenticated} = useAuthContext(); // Assuming 'user' contains user info

  return (
    <Container sx={{ display: "flex", justifyContent: "center" , mt:4,mb:3}}>
      {isAuthenticated ? (
     
       <LoggedInUserInfo redirect={"/sign-up"}/>
       
      ) : (
        <Box
          sx={{
            maxWidth: "400px",
            border: "2px solid #eee",
            borderRadius: "10px",
            padding: "25px",
            marginTop: "25px",
          }}
        >
          <SignUpComponent />
        </Box>
      )}
    </Container>
  );
};

export default SignUpPage;
