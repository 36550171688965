import { fetchWrapper } from "@gogig-in/gogig-clients";
import {   SpotrueApiBaseUrl } from "./baseUrls";

export interface ClientFormData {
  fullName: string;
  emailId: string;
  companyName:string;
  // phoneNumber: string;
  // transitMediaToVerify: string;
  // quantity: string;
}

export default function saveClientFormData(data: ClientFormData) {
  return fetchWrapper(`${SpotrueApiBaseUrl}/saveClientFormData`, {
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
