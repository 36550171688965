import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  styled,
  useTheme,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import VerifiedIcon from "@mui/icons-material/Verified";
// Styled components
const IconWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "50%",
  width: "64px",
  height: "64px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

// interface IconProps {
//   src: string;
// }

// const StepIcon: React.FC<IconProps> = ({ src }) => (
//   <Box
//     component="img"
//     src={src}
//     alt="Step Icon"
//     sx={{
//       width: "32px",
//       height: "32px",
//       filter:
//         "brightness(0) saturate(100%) invert(39%) sepia(13%) saturate(1794%) hue-rotate(86deg) brightness(94%) contrast(88%)",
//     }}
//   />
// );

interface ProcessStepProps {
  iconSrc: React.JSX.Element;
  title: string;
  description: string;
}

const ProcessStep: React.FC<ProcessStepProps> = ({
  iconSrc,
  title,
  description,
}) => {
  const theme = useTheme();

  return (
    <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <IconWrapper>
        <Box
           sx={{
            width: "32px", // Keep the container size the same
            height: "32px", // Keep the container size the same
            filter:
              "brightness(0) saturate(100%) invert(39%) sepia(13%) saturate(1794%) hue-rotate(86deg) brightness(94%) contrast(88%)",
            transform: "scale(1.5)", // Scale the icon to 2x its original size
            transformOrigin: "center", // Ensure the icon scales from the center
          }}
        >
          {iconSrc}
        </Box>
      </IconWrapper>
      <Typography
        variant="h6"
        component="h3"
        sx={{
          fontWeight: 600,
          mb: 2,
          fontSize: "1.5rem", // Increased font size for the title
          color: theme.palette.text.primary,
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.text.secondary,
          maxWidth: "90%",
          margin: "0 auto",
          lineHeight: 1.7,
        }}
      >
        {description}
      </Typography>
    </Box>
  </Grid>
  );
};

const AuditToolProcess: React.FC = () => {
  const theme = useTheme(); // Get the theme

  const steps = [
    {
      iconSrc: <CloudUploadIcon fontSize="large" />, // Use MUI Icon
      title: "Upload",
      description:
        "Sign up and upload an Excel file containing vehicle registration numbers and a PPT of branded vehicle images.",
    },
    {
      iconSrc: <AutoModeIcon fontSize="large" />, // Use MUI Icon
      title: "Process",
      description:
        "Submit the files after uploading. Processing takes 2-3 days using AI/ML and manual verification.",
    },
    {
      iconSrc: <VerifiedIcon fontSize="large" />, // Use MUI Icon
      title: "Verify",
      description:
        "Once processing is complete, an email will be sent with a link and login credentials to download the report.",
    },
  ];

  return (
    <Box sx={{ backgroundColor: theme.palette.background.default }} py={6}>
      <Container maxWidth="lg">
        {/* Header */}
        <Box sx={{ textAlign: "center", mb: 8 }}>
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontWeight: 700,
              color: theme.palette.text.primary, // Primary text color
              fontSize: { xs: "2rem", md: "2.5rem" },
              lineHeight: 1.2,
              mb: 1,
            }}
          >
            How spotrue works?
          </Typography>
        </Box>

        {/* Process Steps */}
        <Grid container spacing={6}>
          {steps.map((step, index) => (
            <ProcessStep
              key={index}
              iconSrc={step.iconSrc}
              title={step.title}
              description={step.description}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default AuditToolProcess;
