import React, { useRef } from 'react';
import { Box, Typography, Container, Paper, Grid, Link } from '@mui/material';

const SpotrueLearnMore: React.FC = () => {
  // Create a map to store refs for each section
  const sectionRefs = useRef<Map<string, HTMLDivElement>>(new Map());

  // Function to scroll to a specific section with an offset
  const scrollToSection = (sectionId: string) => {
    const section = sectionRefs.current.get(sectionId);
    if (section) {
      const offset = 80; // Adjust this value to match your header height
      const top = section.offsetTop - offset;
      window.scrollTo({ top, behavior: 'smooth' });
    }
  };

  // Function to add a ref to the map
  const addSectionRef = (sectionId: string, ref: HTMLDivElement | null) => {
    if (ref) {
      sectionRefs.current.set(sectionId, ref);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ backgroundColor: 'white', py: 4 }}>
      <Box sx={{ my: 4 }}>
        {/* Page Title */}
        <Typography variant="h3" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Transparency in Transit Advertising
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom align="center" sx={{ color: 'text.secondary' }}>
          Restoring Trust with SpoTrue
        </Typography>

        {/* Introduction Section */}
        <Paper elevation={3} sx={{ p: 3, my: 4 , backgroundColor: 'white'}}>
          <Typography variant="body1" paragraph>
            Transit advertising has long been an effective medium for reaching diverse audiences in urban landscapes. From auto-rickshaws to cabs and buses, these moving billboards traverse the streets, offering brands unparalleled visibility.
          </Typography>
          <Typography variant="body1" paragraph>
            However, over the past few years, the integrity of transit advertising has been compromised. The emergence of unethical practices, such as the manipulation of photographic evidence to inflate the number of branded vehicles, has led to a sharp decline in the perceived value of this advertising medium.
          </Typography>
          <Typography variant="body1" paragraph>
            To combat this pervasive issue, gOGig.in has developed SpoTrue, an AI and ML-based audit tool designed to restore trust, accuracy, and transparency in transit advertising.
          </Typography>
        </Paper>

        {/* Table of Content Section */}
        <Paper elevation={3} sx={{ p: 3, my: 4, backgroundColor: 'white' }}>
          <Typography variant="h4" component="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
            Table of Content
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Link
                component="button"
                variant="body1"
                onClick={() => scrollToSection('problem')}
                sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer', display: 'block', mt: 1 }}
              >
                • Understanding the Problem: The Decline in Transit Advertising Integrity
              </Link>
              <Link
                component="button"
                variant="body1"
                onClick={() => scrollToSection('concept')}
                sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer', display: 'block', mt: 1 }}
              >
                • SpoTrue: The Concept and Development
              </Link>
              <Link
                component="button"
                variant="body1"
                onClick={() => scrollToSection('functionality')}
                sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer', display: 'block', mt: 1 }}
              >
                • How SpoTrue Works: A Deep Dive into the Functionality
              </Link>
              <Link
                component="button"
                variant="body1"
                onClick={() => scrollToSection('dataInput')}
                sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer', display: 'block', mt: 1 }}
              >
                • Data Input and Processing
              </Link>
              <Link
                component="button"
                variant="body1"
                onClick={() => scrollToSection('aiMl')}
                sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer', display: 'block', mt: 1 }}
              >
                • AI and ML Integration
              </Link>
            </Grid>
            <Grid item xs={12} md={6}>
              <Link
                component="button"
                variant="body1"
                onClick={() => scrollToSection('dataOutput')}
                sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer', display: 'block', mt: 1 }}
              >
                • Data Output and Reporting
              </Link>
              <Link
                component="button"
                variant="body1"
                onClick={() => scrollToSection('dashboard')}
                sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer', display: 'block', mt: 1 }}
              >
                • Dashboard Features
              </Link>
              <Link
                component="button"
                variant="body1"
                onClick={() => scrollToSection('reporting')}
                sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer', display: 'block', mt: 1 }}
              >
                • Reporting Formats
              </Link>
              <Link
                component="button"
                variant="body1"
                onClick={() => scrollToSection('clientInteraction')}
                sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer', display: 'block', mt: 1 }}
              >
                • Client Interaction and User Experience
              </Link>
            </Grid>
          </Grid>
        </Paper>

        {/* Understanding the Problem Section */}
        <Paper elevation={3} sx={{ p: 3, my: 4, backgroundColor: 'white' }} ref={(ref) => addSectionRef('problem', ref)}>
          <Typography variant="h4" component="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
            Understanding the Problem: The Decline in Transit Advertising Integrity
          </Typography>
          <Typography variant="body1" paragraph>
            In the past, advertising on auto-rickshaws, cabs, and buses commanded premium rates due to the high visibility and reach these mediums offered.
          </Typography>
          <Typography variant="body1" paragraph>
            For instance, branding a cab used to cost around Rs. 5000 per vehicle. However, the market has seen a drastic decline, with rates plummeting to as low as Rs. 2500 per vehicle. This decline is not just a result of market dynamics but is largely driven by unethical practices within the industry.
          </Typography>
          <Typography variant="body1" paragraph>
            In a bid to maximize profits, tools are used to manipulate images. Here’s how the manipulation works: A single cab is branded and then multiple photographs of that vehicle are taken. Using image editing software, these images are altered, making it appear as though branding was completed on multiple vehicles. For a project involving 1000 cabs, only 850 might actually be branded, but the report presented to the client falsely claims that all 1000 vehicles were branded.
          </Typography>
        </Paper>

        {/* SpoTrue: The Concept and Development Section */}
        <Paper elevation={3} sx={{ p: 3, my: 4, backgroundColor: 'white' }} ref={(ref) => addSectionRef('concept', ref)}>
          <Typography variant="h4" component="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
            SpoTrue: The Concept and Development
          </Typography>
          <Typography variant="body1" paragraph>
            Being from the tech industry, we at gOGig.in recognized the need for a robust solution that could put an end to these unethical practices. We envisioned a tool that could analyze the data and verify its authenticity. Thus, SpoTrue was born.
          </Typography>
          <Typography variant="body1" paragraph>
            SpoTrue is an advanced audit tool powered by cutting-edge AI and ML technologies. The development of SpoTrue was no small feat. Countless hours of brainstorming, coding, and testing went into creating a tool that could handle the complexities of transit advertising verification. The result is a powerful platform that can process large volumes of data, analyze it with unparalleled precision, and deliver reports that clients can trust.
          </Typography>
        </Paper>

        {/* How SpoTrue Works Section */}
        <Paper elevation={3} sx={{ p: 3, my: 4, backgroundColor: 'white' }} ref={(ref) => addSectionRef('functionality', ref)}>
          <Typography variant="h4" component="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
            How SpoTrue Works: A Deep Dive into the Functionality
          </Typography>
          <Typography variant="body1" paragraph>
            SpoTrue’s core functionality revolves around processing and verifying the data provided. The tool requires two primary inputs: an Excel file and a PowerPoint (PPT) presentation.
          </Typography>
        </Paper>

        {/* Data Input and Processing Section */}
        <Paper elevation={3} sx={{ p: 3, my: 4, backgroundColor: 'white' }} ref={(ref) => addSectionRef('dataInput', ref)}>
          <Typography variant="h4" component="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
            Data Input and Processing
          </Typography>
          <Typography variant="body1" paragraph>
            The Excel file contains vehicle details like registration numbers, driver names, phone numbers, city, location, and date. The PowerPoint presentation contains images of the branded vehicles. SpoTrue extracts 20+ crucial information from these images, such as the Manufacturer, Model, Variant, Location, Background, Dents, patch, and uniqueness of the vehicle.
          </Typography>
        </Paper>

        {/* AI and ML Integration Section */}
        <Paper elevation={3} sx={{ p: 3, my: 4, backgroundColor: 'white' }} ref={(ref) => addSectionRef('aiMl', ref)}>
          <Typography variant="h4" component="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
            AI and ML Integration
          </Typography>
          <Typography variant="body1" paragraph>
            The heart of SpoTrue lies in its AI and ML algorithms. These technologies enable the tool to analyze data with a level of precision that would be impossible for a human to achieve.
          </Typography>
        </Paper>

        {/* Data Output and Reporting Section */}
        <Paper elevation={3} sx={{ p: 3, my: 4, backgroundColor: 'white' }} ref={(ref) => addSectionRef('dataOutput', ref)}>
          <Typography variant="h4" component="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
            Data Output and Reporting
          </Typography>
          <Typography variant="body1" paragraph>
            SpoTrue generates a comprehensive report that provides clients with a clear and accurate picture of their advertising campaign. The output is delivered in the form of an Excel sheet where tampered data is highlighted.
          </Typography>
        </Paper>

        {/* Dashboard Features Section */}
        <Paper elevation={3} sx={{ p: 3, my: 4, backgroundColor: 'white' }} ref={(ref) => addSectionRef('dashboard', ref)}>
          <Typography variant="h4" component="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
            Dashboard Features
          </Typography>
          <Typography variant="body1" paragraph>
            SpoTrue’s user interface is designed with simplicity and functionality in mind. The tool includes a dashboard that allows clients to manage their data, track the status of their audits, and download their reports.
          </Typography>
        </Paper>

        {/* Reporting Formats Section */}
        <Paper elevation={3} sx={{ p: 3, my: 4, backgroundColor: 'white' }} ref={(ref) => addSectionRef('reporting', ref)}>
          <Typography variant="h4" component="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
            Reporting Formats
          </Typography>
          <Typography variant="body1" paragraph>
            SpoTrue offers three tiers of reporting: Basic, Advanced, and Pro. Each report is designed to cater to different client needs, providing varying levels of detail and analysis.
          </Typography>
        </Paper>

        {/* Client Interaction and User Experience Section */}
        <Paper elevation={3} sx={{ p: 3, my: 4, backgroundColor: 'white' }} ref={(ref) => addSectionRef('clientInteraction', ref)}>
          <Typography variant="h4" component="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
            Client Interaction and User Experience
          </Typography>
          <Typography variant="body1" paragraph>
            SpoTrue is designed to be user-friendly, ensuring that clients can easily navigate the tool and access the information they need. The client dashboard is intuitive, with clear labels and easy-to-understand metrics.
          </Typography>
        </Paper>

        {/* Call to Action */}
        <Box sx={{ textAlign: 'center', my: 6, backgroundColor: 'white' }}>
          <Typography variant="h5" component="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
            Ready to Restore Trust in Transit Advertising?
          </Typography>
          <Typography variant="body1" paragraph>
            Contact us today to learn more about how SpoTrue can help your business.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default SpotrueLearnMore;